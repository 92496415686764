































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { AxiosError } from "axios";

import i18n from "@/plugins/i18n";
import { axiosInstance as axios } from "@/store/";

interface PasswordStrengthInfo {
  color: string;
  progress: number;
}

@Component
export default class ProfileDeleteAccount extends Vue {
  alert = {
    message: "",
    type: "error",
    show: false,
  };
  valid = false;
  sending = false;

  showPassord = false;

  password = "";

  passwordRules = [
    (v: unknown): boolean | string =>
      !!v || i18n.t("profile.deleteAccount.form.validation.password.required").toString(),
  ];
  checkboxRules = [
    (v: string): boolean | string => !!v || i18n.t("profile.deleteAccount.form.validation.confirm.required").toString(),
  ];

  $refs!: {
    form: HTMLFormElement;
  };

  async submit(): Promise<void> {
    if (this.$refs.form.validate()) {
      this.sending = true;
      try {
        await axios.post("/auth/user/deleteAccount", {
          password: this.password,
        });
        this.$store.direct.commit.SHOW_TOAST({ text: this.$t("profile.deleteAccount.notifications.done").toString() });
        this.$emit("back");
      } catch (err) {
        this.alert.type = "error";
        this.alert.message = this.$t("profile.deleteAccount.form.error.generic").toString();
        if ((err as AxiosError).isAxiosError) {
          const e = err as AxiosError;
          if (e.response?.status === 403)
            this.alert.message = this.$t("profile.deleteAccount.form.error.code403").toString();
        }
        this.alert.show = true;
      }
      this.sending = false;
    }
  }
}
