

















































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import ProfileUpdateEmail from "@/components/ProfileUpdateEmail.vue";
import ProfileUpdatePassword from "@/components/ProfileUpdatePassword.vue";
import ProfileDeleteAccount from "@/components/ProfileDeleteAccount.vue";

enum State {
  PROFILE,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  DELETE_ACCOUNT,
}

@Component({
  components: {
    ProfileUpdateEmail,
    ProfileUpdatePassword,
    ProfileDeleteAccount,
  },
})
export default class AccountView extends Vue {
  state: State = State.PROFILE;
  State = State;

  get login(): string {
    return this.$store.direct.state.authentication.user?.username || "";
  }

  get email(): string {
    return this.$store.direct.state.authentication.user?.email || "";
  }

  get birthDate(): string {
    return this.$store.direct.state.authentication.user?.birthDate?.toLocaleDateString() || "";
  }

  async logout(): Promise<void> {
    await this.$store.direct.dispatch.authentication.logout();
    this.$router.push("/");
    location.reload();
  }
}
