
















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { AxiosError } from "axios";

import i18n from "@/plugins/i18n";
import { axiosInstance as axios } from "@/store/";

import EmailNew from "@/components/inputs/EmailNew.vue";
import Password from "@/components/inputs/Password.vue";

@Component({
  components: {
    EmailNew,
    Password,
  },
})
export default class ProfileUpdateEmail extends Vue {
  alert = {
    message: "",
    type: "error",
    show: false,
  };
  valid = false;
  sending = false;

  showPassword = false;

  newEmail = "";
  password = "";

  $refs!: {
    form: HTMLFormElement;
  };

  async submit(): Promise<void> {
    if (this.$refs.form.validate()) {
      this.sending = true;
      try {
        await axios.put(`/auth/user/updatemail`, {
          password: this.password,
          newEmail: this.newEmail,
        });
        this.$store.direct.commit.SHOW_TOAST({ text: i18n.t("profile.updateEmail.notifications.done").toString() });
        this.$emit("back");
      } catch (err) {
        this.alert.type = "error";
        this.alert.message = i18n.t("profile.updateEmail.form.error.generic").toString();
        if ((err as AxiosError).isAxiosError) {
          const e = err as AxiosError;
          if (e.response?.status === 403)
            this.alert.message = i18n.t("profile.updateEmail.form.error.code403").toString();
        }
        this.alert.show = true;
      }
      this.sending = false;
    }
  }
}
